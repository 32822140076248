import { PT } from "~/utils/products";

export const commaSeparatedSkus = (list: { sku: string }[]) => {
  return [...new Set(list.map((item) => item.sku))].join(",");
};

export const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  const popped = parts.length === 2 ? parts.pop() : null;
  return popped?.split(";").shift();
};

export const deleteCookie = (name: string, path: string = "/"): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
};

// TODO - TESTING ONLY, DELETE!
export const setCookie = (favourites: [string, number][]): void => {
  const value = JSON.stringify(favourites);

  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 30);
  document.cookie = `romaprd.favourites=${value}; expires=${expirationDate.toUTCString()}; path=/`;
};

// export const productTypes = {
//   [PHOTOFRAME]: 0,
//   [MOULDING]: 1,
//   [GALLERYFRAME]: 2,
//   [MIRROR]: 3,
// };

const reverseFavMap: Record<string, PT> = {
  "0": PT.PHOTOFRAME,
  "1": PT.MOULDING,
  "2": PT.GALLERYFRAME,
  "3": PT.MIRROR,
};

export const getCookieFavourites = () => {
  const arrOfFaves = getCookie("romaprd.favourites");
  const hasBeenMigrated = getCookie("romaprd.favourites") ? false : true;

  let formatted: { sku: string; type: PT; value: { ID: string | null } }[] = [];
  let map: Map<string, { ID: string | null }> = new Map();

  if (arrOfFaves) {
    //
    const parsed: [moulding: string, type: number][] = JSON.parse(arrOfFaves);

    parsed.forEach(([moulding, type]) => {
      // array
      formatted.push({
        sku: moulding,
        type: reverseFavMap[type.toString()],
        value: { ID: null },
      });
      // map
      map.set(`${moulding}-${reverseFavMap[type.toString()]}`, { ID: null });
    });
  }

  return { hasBeenMigrated, formatted, map };
};
